<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="6"
      >
        <b-card
          title="Excel Veri Aktarımı"
        >
          <b-form-group
            label="Dosya Seçin"
            label-for="file"
          >
            <b-form-file
              id="file"
              accept=".xls, .xlsx"
              browse-text="Dosya Seçin"
              placeholder="Dosya Seçin"
              @change="onChange"
            />
          </b-form-group>
          <b-form-group
            label="Çalışma Sayfası Seçiniz"
            label-for="sheets"
          >
            <xlsx-read :file="file">
              <xlsx-sheets>
                <template #default="{sheets}">
                  <v-select
                    id="sheets"
                    v-model="selectedSheet"
                    :options="sheets"
                    placeholder="Seçiniz"
                    :disabled="!file"
                  />
                </template>
              </xlsx-sheets>
            </xlsx-read>
          </b-form-group>
        </b-card>
      </b-col>
      <b-col cols="6">
        <information />
      </b-col>
      <b-col
        v-if="saveStatus.status !== null"
        cols="12"
      >
        <b-card
          title="Aktarım Sonucu"
          class="text-center"
        >
          <b-alert
            show
            :variant="saveStatus.status? 'success' : 'danger'"
          >
            <div class="alert-body">
              <div class="mt-1">
                <FeatherIcon
                  :icon="saveStatus.status? 'CheckCircleIcon' : 'XCircleIcon'"
                  size="36"
                />
              </div>
              <div>
                {{ saveStatus.message }}
              </div>
              <div
                v-if="saveStatus.status"
                class="mt-1"
              >
                <b-button
                  variant="success"
                  :to="'/insurance/renewal/periods/view/' + saveStatus.id"
                >
                  Listeyi Görüntüle
                </b-button>
              </div>
            </div>
          </b-alert>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="selectedSheet"
        cols="12"
      >
        <b-card title="Yenileme Listesi">
          <b-row>
            <b-col>
              <b-form-group
                label="Yenileme Dönemi"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  placeholder="Yenileme Dönemi Adı"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Yenileme Yıl"
                label-for="pyear"
              >
                <b-form-input
                  id="pyear"
                  v-model="pyear"
                  placeholder="Yenileme Dönemi Yılı"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Yenileme Ay"
                label-for="pmonth"
              >
                <v-select
                  id="pmonth"
                  v-model="pmonth"
                  :options="months"
                  placeholder="Seçiniz"
                  label="title"
                  :reduce="item => item.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="danger"
            :disabled="!title"
            @click="importDataSave"
          >
            <FeatherIcon icon="SaveIcon" />
            Kaydet
          </b-button>
        </b-card>
      </b-col>
      <b-col
        v-if="selectedSheet"
        cols="12"
      >
        <b-card
          title="Dosya Önizleme"
        >
          <div class="height-500 overflow-scroll">
            <div
              v-if="!tempData"
              class="text-center"
            >
              <b-spinner />
            </div>
            <b-table
              striped
              small
              hover
              :items="tempData"
              class="font-small-1"
            />
          </div>
          <xlsx-read
            :file="file"
            class="hidden"
          >
            <xlsx-table
              :sheet="selectedSheet"
            />
            <xlsx-json :sheet="selectedSheet">
              <template #default="{collection}">
                <div>
                  {{ collection }}
                  {{ getData(collection) }}
                </div>
              </template>
            </xlsx-json>
          </xlsx-read>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BFormFile, BFormGroup, BRow, BCol, BTable, BButton, BFormInput, BAlert, BSpinner,
} from 'bootstrap-vue'
import {
  XlsxRead, XlsxTable, XlsxSheets, XlsxJson,
} from 'vue-xlsx'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import Information from '@/views/Insurance/Renewal/Import/Information'

export default {
  name: 'Index',
  components: {
    BCard,
    BFormFile,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BButton,
    BFormInput,
    BAlert,
    BSpinner,
    Information,
    vSelect,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
  },
  data() {
    return {
      dateFormated: false,
      submitStatus: false,
      title: null,
      pyear: this.moment().format('Y'),
      pmonth: this.moment().format('M'),
      tempData: null,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
      collection: [{ a: 1, b: 2 }],
      months: [
        { id: '1', title: 'Ocak' },
        { id: '2', title: 'Şubat' },
        { id: '3', title: 'Mart' },
        { id: '4', title: 'Nisan' },
        { id: '5', title: 'Mayıs' },
        { id: '6', title: 'Haziran' },
        { id: '7', title: 'Temmuz' },
        { id: '8', title: 'Ağustos' },
        { id: '9', title: 'Eylül' },
        { id: '10', title: 'Ekim' },
        { id: '11', title: 'Kasım' },
        { id: '12', title: 'Aralık' },
      ],
    }
  },
  computed: {
    saveStatus() {
      return this.$store.getters['insuranceRenewals/dataSaveStatus']
    },
  },
  created() {
    this.$store.commit('insuranceRenewals/SET_DATA_SAVE', {
      message: null,
      status: null,
      id: null,
    })
  },
  methods: {
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null
    },
    getData(colection) {
      this.tempData = colection
      if (this.tempData) {
        if (this.dateFormated === false) {
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const key in this.tempData) {
            const endDate = (Number(this.tempData[key].BITIS) - 25569) * 86400
            // eslint-disable-next-line no-param-reassign
            this.tempData[key].BITIS = this.moment.unix(endDate).format('DD.MM.YYYY')
          }
        }
        this.dateFormated = true
      }
    },
    importDataSave() {
      this.submitStatus = true
      const formData = {
        title: this.title,
        importData: this.tempData,
        pyear: this.pyear,
        pmonth: this.pmonth,
      }
      this.$store.dispatch('insuranceRenewals/renewalImport', formData)
    },
  },
}
</script>
